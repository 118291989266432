import React from 'react'

import Layout from '../components/layout'
import SEO from '../components/seo'

const Locator = () => (
  <Layout>
    <SEO title="Find a Store" />
    <div class="banner_small">
      <div class="container">
        <div class="colx1">
          <div class="copy">
            <h3 class="center">
              Find Cravv<sup>®</sup> at a pharmacy near you
            </h3>
          </div>
        </div>
      </div>
    </div>
    <div class="section">
      <div class="container">
        <div class="colx1">
          <iframe class="map" width="100%" height="100%" scrolling="yes" frameborder="no" src="https://www.google.com/maps/d/u/0/embed?mid=1CwUQn51EejodU5x0Tz15WArr1sUhQryq"></iframe>
        </div>
      </div>
    </div>
  </Layout>
)

export default Locator
